<template>
    <div
        class="coworking-campus-oa-approval-manage-form ding"
        v-loading="false"
    >
        <global-page-back
            :detailPageTitle="pageTitle"
            @handlerGlobalType="back"
        >
            <template #globalPageBackTabs>
                <div class="tabs-list">
                    <div
                        v-for="(item, index) of processList"
                        :key="index"
                        :class="['tabs-item', {current: item.sel}]"
                        @click="processComp(index)"
                    >
                        {{ item.text }}
                    </div>
                </div>
            </template>
        </global-page-back>
        <div class="process-content">
            <component
                v-bind:is="comp"
                ref="comp"
                :designNewListParent="designNewListParentData"
                :form="basicInformationFilling.ruleForm"
                :rules="basicInformationFilling.rules"
                :isEditRadio="isEditRadio"
                @throwOutList="throwOutList"
                @deletedItems="deletedItems"
                @inputFocus="inputFocus"
                @userPopulationHandlerChange="userPopulationHandlerChange"
            ></component>
        </div>
        <xk-tree-shape
            v-if="dialogTree.dialogVisible"
            title="发起人"
            :show="dialogTree.dialogVisible"
            :list="selTreeOptList"
            :selectObjData="selectObjData"
            @getNextLevelTreeList="getNextLevelTreeList"
            @determine="determine"
            @closeDialog="closeDialog"
        >
        </xk-tree-shape>

        <div class="foott">
            <el-button
                type="primary"
                @click="processComp(0)"
                v-if="!nextBtn"
                >上一步</el-button
            >
            <el-button
                v-else
                type="primary"
                @click="processComp(1)"
                >下一步</el-button
            >
            <el-button
                type="primary"
                :loading="btnLoding"
                @click="publish"
                >发布</el-button
            >
        </div>
    </div>
</template>
<script>
import {DialogWrapper, debounce} from "common-local";
// 引入基础数据
import approvalManagement from "@/assets/data/InformationCollection.js";
// 1.表单设计 2.配置信息
import FormDesign from "./CreatForm/FormDesign.vue";
import ConfigurationInformation from "./CreatForm/ConfigurationInformation.vue";
// 引入ajax models
import {CampusOAModel} from "@/models/CampusOA";
import {CommonModel} from "@/models/Common.js";
// 引入vuex
import {mapState} from "vuex";
import VirtualTree from "@/components/scrollWrapper/Sub/VirtualTree.vue";
import {v4 as uuidv4} from "uuid";
import {removeTreeShapeDialog} from "@/libs/utils.js";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
export default {
    name: "CreatForm",
    components: {
        GlobalPageBack,
        DialogWrapper,
        FormDesign,
        VirtualTree,
        ConfigurationInformation,
    },
    props: {
        showOverallDisplayOriData: Object,
        showOverallDisplayStatus: Boolean,
        formData: String,
        type: String,
    },
    data() {
        return {
            btnLoding: false,
            nextBtn: true,
            approvalManagement,
            comps: ["FormDesign", "ConfigurationInformation"],
            processList: [],
            designNewListParentData: [],
            schoolIdObj: {
                schoolId: "",
                applyPerson: "",
                peopleName: "",
            }, // 获取所在分组
            index: "",
            isEditRadio: true, // 是否可编辑
            basicInformationFilling: {
                ruleForm: {
                    id: "", // 表单唯一标识符
                    schoolId: "", // 学校ID
                    formName: "", // 表单名称
                    formType: "0", // 表单类型0其他1学生评价教师2学生评价班主任
                    userPopulation: "", // 填写范围：1教职工，2家长 3 不限范围
                    organInfo: "", // 组织信息，表单适用的组织范围
                    organInfoName: "", // 组织名字
                    formInfo: "", // 表单问题内容的JSON字符串表示
                    allowEdit: "1", // 是否允许编辑：0表示不允许 1表示允许
                    releaseStatus: "1", // 发布状态：发布状态1未发布2进行中3已结束
                    releaseTime: "", // 发布时间
                    deadline: "", // 截止时间
                    remark: "", // 备注
                },
                rules: {
                    formName: [
                        {
                            required: true,
                            message: "请输入表单名称",
                            trigger: "blur",
                        },
                    ],
                    userPopulation: [
                        {
                            required: true,
                            message: "请选择填写范围",
                            trigger: "blur",
                        },
                    ],
                    allowEdit: [
                        {
                            required: true,
                            message: "请选择再次编辑",
                            trigger: "blur",
                        },
                    ],
                    deadline: [
                        {
                            required: true,
                            message: "请选择截止时间",
                            trigger: "blur",
                        },
                    ],
                },
            }, // 基础信息
            submitData: {}, // 提交数据集合
            // 请求组织机构数据
            treeData: {
                schoolId: "",
                type: "",
                parentId: "",
                name: "",
                organType: "",
            },
            /**
             * 组织机构在不同的适用人群变化不同的值
             * facultyStaff 教职工 1
             * studentParents 学生家长 2
             * StaffParents 教职工及学生家长
             * */
            facultyStaff: {
                type: "teacher,organ",
                organType: "1,5",
            },
            studentParents: {
                type: "student,organ",
                organType: "2,3,4,5",
            },
            staffParents: {
                type: "teacher,student,organ",
                organType: "1,2,3,4,5",
            },
            selTreeOptList: [],
            selectObjData: [], // 选择组织机构数据
            /**
             * 树形数据开关 dialogTree
             * 树形返回数据 selTreeOptList
             * 已经选择属性数据对象 selectObjData
             *
             * */
            dialogTree: {
                title: "选择发起人",
                dialogVisible: false,
            },
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
            schoolId: (state) => state.schoolId,
        }),
        comp() {
            return this.comps[this.index];
        },
        backImg() {
            return require("@/assets/images/back_.png");
        },
        processSelImg() {
            return require("@/assets/images/process-sel.png");
        },
        pageTitle() {
            if (
                this.showOverallDisplayOriData &&
                this.showOverallDisplayOriData.formName
            ) {
                return this.showOverallDisplayOriData.formName;
            } else {
                return "添加表单";
            }
        },
    },
    async created() {
        this.designNewListParentData = [];
        if (this.type == "edit" || this.type == "copy") {
            let result = await this.getFormInfo(this.formData);
            for (const key in this.basicInformationFilling.ruleForm) {
                this.basicInformationFilling.ruleForm[key] = result[key];
                if (key == "formInfo") {
                    let arr = JSON.parse(JSON.parse(result[key]));
                    this.basicInformationFilling.ruleForm[key] =
                        JSON.stringify(arr);
                    this.designNewListParentData = arr;
                }
                if (this.type == "copy" && key == "organInfo") {
                    let organInfoArr = JSON.parse(
                        this.basicInformationFilling.ruleForm[key],
                    );
                    organInfoArr = organInfoArr.map((item) => {
                        item.id = "";
                        return item;
                    });
                    this.basicInformationFilling.ruleForm[key] =
                        JSON.stringify(organInfoArr);
                }
            }

            let sele = JSON.parse(result.organInfo).map((element) => {
                return {
                    ...element,
                    id: element.bussinessId,
                };
            });
            this.selectObjData = sele;
            this.basicInformationFilling.ruleForm["organInfoName"] =
                this.selectObjData.map((item) => item.name).join(",");

            let formName = ["EvaluateTeachers", "EvaluateTeachersBan"];
            this.isEditRadio = true;
            this.designNewListParentData.forEach((item) => {
                if (formName.includes(item.compType)) {
                    this.isEditRadio = false;
                }
            });
        }

        if (this.type == "copy") {
            this.basicInformationFilling.ruleForm["id"] = "";
            this.basicInformationFilling.ruleForm["releaseStatus"] = "1";
            this.basicInformationFilling.ruleForm["releaseTime"] = "";
            this.basicInformationFilling.ruleForm["deadline"] = "";
        }

        this.basicInformationFilling = JSON.parse(
            JSON.stringify(this.basicInformationFilling),
        );

        let userPopulation =
            this.basicInformationFilling.ruleForm["userPopulation"];
        console.debug(userPopulation, "sdfasdfasffwfrwfwfwfwwf");

        let status = userPopulation ? userPopulation : "1";
        this.whoCanInitiate(status);
        this.$eventDispatch("setGlobalPageType", "form");
        this.init();
        this.index = 0;
    },
    watch: {
        "basicInformationFilling.ruleForm.formType": {
            immediate: false,
            deep: true,
            handler(newValue, oldValue) {
                console.debug(newValue, "经过zheli8");
                let status = JSON.parse(
                    JSON.stringify(
                        this.basicInformationFilling.ruleForm.userPopulation,
                    ),
                );
                if (newValue == "1" || newValue == "2") {
                    status = "2";
                }
                this.basicInformationFilling.ruleForm.userPopulation = status;
            },
        },
        "basicInformationFilling.ruleForm.userPopulation": {
            immediate: false,
            deep: true,
            handler(newValue, oldValue) {
                if (!oldValue) return;
                this.selectObjData = [];
                this.basicInformationFilling.ruleForm.organInfoName = "";
                this.basicInformationFilling.ruleForm.organInfo = "";
            },
        },
        index: {
            immediate: false,
            deep: true,
            handler(newValue, oldValue) {
                this.nextBtn = true;
                if (newValue == 1) {
                    this.nextBtn = false;
                }
            },
        },
    },
    methods: {
        /**
         * @Description: 初始化
         * @Author: 周浩
         * @Date: 2024-8-7 17:07:22
         */
        init() {
            this.initData();
            this.getTreeData();
        },
        getFormInfo(id) {
            return new Promise((resolve, reject) => {
                this._get(`/school/schoolCollectForm/info/${id}`).then(
                    (res) => {
                        console.debug(JSON.parse(JSON.stringify(res)));
                        resolve(res.data.data);
                    },
                );
            });
        },

        /**
         * @Description: 数据初始化
         * @Author: 周浩
         * @Date: 2024-8-7 17:07:22
         */
        initData() {
            this.processList = JSON.parse(
                JSON.stringify(this.approvalManagement.processList),
            );
            this.schoolIdObj.schoolId = this.schoolId;
            this.treeData.schoolId = this.schoolId;
            this.basicInformationFilling.ruleForm.schoolId = this.schoolId;
        },

        back() {
            // 清空基础信息填写表单
            // 重置所有数据

            this.$emit("changeComp", {
                type: "",
                component: "InterconnectionInfoCollection",
                data: "",
            });
        },
        // 发布
        async publish() {
            console.debug(
                JSON.parse(
                    JSON.stringify(this.basicInformationFilling.ruleForm),
                ),
            );

            if (this.type == "edit") {
                let result = await this.getFormInfo(this.formData);
                if (result.releaseStatus == "3") {
                    this.$message.error("表单已结束，不可提交！");
                    return;
                }
                if (result.releaseStatus == "2") {
                    this.$message.error("表单已发布，不可提交！");
                    return;
                }
            }

            // return;

            let isGo = this.yan(this.basicInformationFilling.ruleForm);
            if (isGo) {
                this.btnLoding = true;
                if (this.type == "edit") {
                    this.editFrom();
                    return;
                }
                this.saveFrom();
            }
        },

        yan(obj) {
            let {formInfo, releaseTime, deadline, userPopulation, organInfo} =
                obj;
            if (!formInfo || formInfo == "[]") {
                this.$message.error("表单设计选项，未选择任何数据！");
                return false;
            }
            let isNext = true;
            console.debug(deadline, "截止时间");
            Object.keys(this.basicInformationFilling.rules).forEach((item) => {
                if (!obj[item]) {
                    isNext = false;
                }
            });
            if (!isNext) {
                this.$message.error("配置信息填写选项，必填项未填写。");
            }

            if (isNext) {
                isNext = true;
                console.debug(userPopulation, "userPopulation");
                if (userPopulation != 3) {
                    if (!organInfo || organInfo == "[]") {
                        this.$message.error("请选择填写范围");
                        isNext = false;
                    }
                }

                if (isNext) {
                    isNext = false;
                    let nowTime = new Date().getTime();
                    let begin = new Date(releaseTime).getTime();
                    let end = new Date(deadline).getTime();

                    if (releaseTime) {
                        if (end >= nowTime && end > begin) {
                            isNext = true;
                        }
                    } else {
                        if (end >= nowTime) {
                            isNext = true;
                        }
                    }

                    if (!isNext) {
                        this.$message.error(
                            "截止时间需要大于发布时间和当前时间。",
                        );
                    }
                }
            }

            return isNext;
        },
        /**
         * @Description: 拿去表单JSON
         * @Author: 周浩
         * @Date: 2024-8-8 15:21:20
         */
        throwOutList(data) {
            console.debug(JSON.parse(JSON.stringify(data)), "拿到daJOSON");
            this.designNewListParentData = data;
            this.fanWeiDataInit(data);
            this.basicInformationFilling.ruleForm.formInfo =
                JSON.stringify(data);
            return;
        },
        /**
         * @Description: 拿去表单JSON
         * @Author: 周浩
         * @Date: 2024-8-8 15:21:20
         */
        fanWeiDataInit(data) {
            let formName = ["EvaluateTeachers", "EvaluateTeachersBan"];
            let formType = "0";
            this.isEditRadio = true;
            data.forEach((item) => {
                if (formName.includes(item.compType)) {
                    //套件
                    this.isEditRadio = false;
                    formType = "1";
                    if (item.compType == "EvaluateTeachersBan") {
                        formType = "2";
                    }
                    this.whoCanInitiate("1");
                }
            });
            this.basicInformationFilling.ruleForm.formType = formType;
        },
        /**
         * @Description: 切换流程
         * @Author: 周浩
         * @Date: 2024-8-8 15:21:20
         */
        processComp(index) {
            this.index = index;
            this.processList.forEach((ite, idx) => {
                if (index == idx) {
                    ite.sel = true;
                } else {
                    ite.sel = false;
                }
            });
            //this.getBasicInformationData()
        },
        deletedItems(ori) {},
        /**
         * @Description: 焦点触发
         * @Author: 周浩
         * @Date: 2024-8-8 11:54:06
         */
        async inputFocus(key) {
            switch (key) {
                case "initiate":
                    this.treeData.parentId = "";
                    await this.getTreeData();

                    console.debug(this.selectObjData);
                    this.dialogTree.dialogVisible = true;
                    break;
                case "":
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 谁可以发起状态修改
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-22 13:52:38
         */
        whoCanInitiate(userPopulation) {
            switch (userPopulation) {
                case "1":
                    this.treeData.type = this.facultyStaff.type;
                    this.treeData.organType = this.facultyStaff.organType;
                    break;
                case "2":
                    this.treeData.type = this.studentParents.type;
                    this.treeData.organType = this.studentParents.organType;
                    break;
                case "3":
                    this.treeData.type = this.staffParents.type;
                    this.treeData.organType = this.staffParents.organType;
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 组织架构
         * @Author: 周浩
         * @Date: 2024-8-8 11:55:39
         */
        async getTreeData(type) {
            const commonModel = new CommonModel();
            await commonModel.getAddressBookList(this.treeData).then((res) => {
                if (res.data.code == "200") {
                    this.selTreeOptList = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 组织机构请求下一级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-21 11:35:53
         * @param {*} data
         */
        async getNextLevelTreeList(data) {
            const {id, name} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            await this.getTreeData();
            this.$eventBroadcast("getNewTreeList");
        },
        /**
         * @Description: 返回组织机构结果
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-21 11:36:21
         */
        determine(data) {
            this.selectObjData = data;
            this.setFormData(JSON.parse(JSON.stringify(data)));
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
            this.dialogTree.dialogVisible = false;
        },
        /**
         * @Description: 关闭组织机构弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-21 11:36:21
         */
        closeDialog() {
            this.dialogTree.dialogVisible = false;
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
        },
        /**
         * @Description: 数据回显
         * @Author: 周浩
         * @Date: 2024-8-8 14:35:31
         */
        setFormData(data) {
            let aa = JSON.parse(JSON.stringify(data));

            aa = aa.map((item) => {
                let {id, ...obj} = item;
                return {
                    ...obj,
                    bussinessId: id,
                    deleteStatus: 0,
                };
            });
            this.basicInformationFilling.ruleForm.organInfo =
                JSON.stringify(aa);
            this.basicInformationFilling.ruleForm.organInfoName = data
                .map((item) => item.name)
                .join(",");
        },
        async userPopulationHandlerChange(userPopulation, isGo = false) {
            this.whoCanInitiate(userPopulation);
            if (!isGo) {
                this.selectObjData = [];
                this.basicInformationFilling.ruleForm.organInfoName = "";
                this.basicInformationFilling.ruleForm.organInfo = "";
            }
            await this.getTreeData();
        },
        /**
         * @Description: 保存表单
         * @Author: 周浩
         * @Date: 2024-8-8 15:05:49
         */
        saveFrom() {
            this._fet(
                "/school/schoolCollectForm/save",
                this.basicInformationFilling.ruleForm,
            ).then((res) => {
                console.debug(JSON.parse(JSON.stringify(res)), "save");
                this.btnLoding = false;
                this.$emit("changeComp", {
                    type: "",
                    component: "InterconnectionInfoCollection",
                    data: "",
                });
            });
        },
        editFrom() {
            this._fet(
                "/school/schoolCollectForm/edit",
                this.basicInformationFilling.ruleForm,
            ).then((res) => {
                console.debug(JSON.parse(JSON.stringify(res)), "save");
                this.btnLoding = false;
                this.$emit("changeComp", {
                    type: "",
                    component: "InterconnectionInfoCollection",
                    data: "",
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.close-btn {
    margin: 30px 0;
    display: flex;
    justify-content: center;
}
.coworking-campus-oa-approval-manage-form {
    display: flex;
    flex-direction: column;
    // width: 100%;
    height: 100%;
    display: flex;
    .process-opt {
        display: flex;
        justify-content: space-between;
        height: 55px;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px;
        overflow: hidden;
        .back {
            display: flex;
            align-items: center;

            .back-img {
                margin-right: 7px;
                margin-top: -2px;
                cursor: pointer;
            }
        }
        .process-list {
            display: flex;
            align-items: center;

            .process-item {
                position: relative;
                height: 56px;
                line-height: 56px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #878787;
                margin: 0 31px;
                cursor: pointer;
                overflow: hidden;

                .process-sel-img {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                }
            }

            .process-item.current {
                color: #3c7fff;
            }
        }
    }
}

.tabs-list {
    flex: 1;
    justify-content: center;
    position: relative;
    display: flex;
    height: 48px;
    line-height: 48px;

    .tabs-item {
        width: 130px;
        position: relative;
        text-align: center;
        font-size: 15px;
        color: #606c80;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #3c7fff;
        font-weight: 600;
        &:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 8px solid #3c7fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(calc(-50% + 12px));
        }
    }
}

.process-content {
    border-radius: 4px;
    // background-color: #fff;
    // margin: 0px 10px 10px 0;
    height: 100%;
    overflow: auto;
    width: 100%;
}
.ding {
    position: relative;
    .foott {
        position: absolute;
        bottom: 10px;
        width: 100%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
    }
}
</style>
